<template>
  <div id="menu-permission-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>
          {{ PageTitle }}
        </h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-container class="py-0">
        <v-speed-dial
          v-model="FloatingButtonOptions.fab"
          :top="FloatingButtonOptions.top"
          :bottom="FloatingButtonOptions.bottom"
          :right="FloatingButtonOptions.right"
          :left="FloatingButtonOptions.left"
          :direction="FloatingButtonOptions.direction"
          :open-on-hover="FloatingButtonOptions.hover"
          :transition="FloatingButtonOptions.transition"
          :fixed="true"
          v-if="FloatingButtonOptions.SpeedDialFlag"
        >
          <template v-slot:activator>
            <v-btn
              v-model="FloatingButtonOptions.fab"
              color="blue darken-2"
              dark
              fab
            >
              <v-icon v-if="FloatingButtonOptions.fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="FloatingButtonOptions.CreateButtonFlag"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="indigo"
                @click.prevent="addRecordPrompt = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  FloatingButtonOptions.EditButtonFlag && tableData1.length > 0
                "
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="green"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  FloatingButtonOptions.DeleteButtonFlag &&
                  tableData1.length > 0
                "
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="red"
                @click.prevent="deleteConfirmAlert"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-speed-dial>
      </v-container>

      <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
        <add-new-menu
          pageTitle="Create Menu"
          pageDescription="Add new menu"
          :addRecordPrompt="addRecordPrompt"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></add-new-menu>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ModuleCodeOptions"
                  :rules="ModuleCodeRules"
                  :loading="ModuleCodeOptionsLoading"
                  v-model="ModuleCode"
                  label="Module"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="DesignationCode"
                  :rules="DesignationCodeRules"
                  :items="DesignationCodeOptions"
                  :loading="DesignationCodeOptionsLoading"
                  label="Designation"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>

          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :show-select="FloatingButtonOptions.ShowSelectFlag"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:[`item.parentTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.parentTxt"
                    @click="
                      changePermission(
                        item.parentTxtId,
                        item.parentTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.parentTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.parentTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.parentTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.listTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.listTxt"
                    @click="
                      changePermission(
                        item.listTxtId,
                        item.listTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.listTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.listTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.listTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.createTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.createTxt"
                    @click="
                      changePermission(
                        item.createTxtId,
                        item.createTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.createTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.createTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.createTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.editTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.editTxt"
                    @click="
                      changePermission(
                        item.editTxtId,
                        item.editTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.editTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.editTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.editTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.deleteTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.deleteTxt"
                    @click="
                      changePermission(
                        item.deleteTxtId,
                        item.deleteTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.deleteTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.deleteTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.deleteTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.approvalTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.approvalTxt"
                    @click="
                      changePermission(
                        item.approvalTxtId,
                        item.approvalTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.approvalTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.approvalTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.approvalTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.cancelTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.cancelTxt"
                    @click="
                      changePermission(
                        item.cancelTxtId,
                        item.cancelTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.cancelTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.cancelTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.cancelTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.previewTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.previewTxt"
                    @click="
                      changePermission(
                        item.previewTxtId,
                        item.previewTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.previewTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.previewTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.previewTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.printTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.printTxt"
                    @click="
                      changePermission(
                        item.printTxtId,
                        item.printTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.printTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.printTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.printTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.dashboardTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.dashboardTxt"
                    @click="
                      changePermission(
                        item.dashboardTxtId,
                        item.dashboardTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.dashboardTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.dashboardTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.dashboardTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.reportTxt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.reportTxt"
                    @click="
                      changePermission(
                        item.reportTxtId,
                        item.reportTxt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.reportTxt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.reportTxt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.reportTxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.list_1Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.list_1Txt"
                    @click="
                      changePermission(
                        item.list_1TxtId,
                        item.list_1Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.list_1Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.list_1Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.list_1TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.create_1Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.create_1Txt"
                    @click="
                      changePermission(
                        item.create_1TxtId,
                        item.create_1Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.create_1Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.create_1Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.create_1TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.edit_1Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.edit_1Txt"
                    @click="
                      changePermission(
                        item.edit_1TxtId,
                        item.edit_1Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.edit_1Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.edit_1Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.edit_1TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.delete_1Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.delete_1Txt"
                    @click="
                      changePermission(
                        item.delete_1TxtId,
                        item.delete_1Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.delete_1Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.delete_1Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.delete_1TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.preview_1Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.preview_1Txt"
                    @click="
                      changePermission(
                        item.preview_1TxtId,
                        item.preview_1Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.preview_1Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.preview_1Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.preview_1TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.report_1Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.report_1Txt"
                    @click="
                      changePermission(
                        item.report_1TxtId,
                        item.report_1Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.report_1Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.report_1Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.report_1TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.list_2Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.list_2Txt"
                    @click="
                      changePermission(
                        item.list_2TxtId,
                        item.list_2Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.list_2Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label
                      >item
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.list_2Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.list_2TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.create_2Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.create_2Txt"
                    @click="
                      changePermission(
                        item.create_2TxtId,
                        item.create_2Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.create_2Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.create_2Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.create_2TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.edit_2Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.edit_2Txt"
                    @click="
                      changePermission(
                        item.edit_2TxtId,
                        item.edit_2Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.edit_2Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.edit_2Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.edit_2TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.delete_2Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.delete_2Txt"
                    @click="
                      changePermission(
                        item.delete_2TxtId,
                        item.delete_2Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.delete_2Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.delete_2Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.delete_2TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.preview_2Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.preview_2Txt"
                    @click="
                      changePermission(
                        item.preview_2TxtId,
                        item.preview_2Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.preview_2Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.preview_2Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.preview_2TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
                <template v-slot:[`item.report_2Txt`]="{ item }">
                  <v-switch
                    inset
                    v-model="item.report_2Txt"
                    @click="
                      changePermission(
                        item.report_2TxtId,
                        item.report_2Txt,
                        item.DesignationId
                      )
                    "
                    :color="`${item.report_2Txt == 1 ? 'green' : 'red'}`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:label>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.report_2Txt ? "Enabled" : "Disabled"
                          }}</span>
                        </template>
                        {{ item.report_2TxtName }}
                      </v-tooltip>
                    </template>
                  </v-switch>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import AddNewMenu from "@/view/pages/erp/menu/AddNewMenu.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    AddNewMenu,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      rows: [],

      TableLoadingFlag: false,
      tableData1: [],
      tableColumns1: [],
      tableOptions1: {},
      selected: [],
      search: "",

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      ModuleCodeRules: [(v) => !!v || "Module is required"],
      ModuleCode: "",
      ModuleCodeOptions: [],
      ModuleCodeOptionsLoading: false,

      addRecordPrompt: false,

      DesignationCodeRules: [],
      DesignationCode: "",
      DesignationCodeOptions: [],
      DesignationCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: " ",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
        this.getDesignationCodeOptions();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    ModuleCode: function () {
      console.log("watch ModuleCode");
      this.tableData1 = [];
      this.tableColumns1 = [];
      this.tableOptions1 = {};
      this.selected = [];
      this.search = "";
    },
    DesignationCodeOptions: function () {
      console.log("watch DesignationCodeOptions");
      this.LoadingFlag = false;
      this.DesignationCodeOptionsLoading = false;
      console.log("watch DesignationCode");
      this.tableData1 = [];
      this.tableColumns1 = [];
      this.tableOptions1 = {};
      this.selected = [];
      this.search = "";
    },
    ModuleCodeOptions: function () {
      console.log("watch ModuleCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ModuleCodeOptionsLoading = false;
    },
    RoleCodeOptions: function () {
      console.log("watch RoleCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.RoleCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "menu_permission",
        Action: "list_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getDesignationCodeOptions();
      this.getModuleCodeOptions();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      // this.getTableRecords();
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/lom/details";
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        var params = {
          id: LomId,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    getDesignationCodeOptions() {
      console.log("DesignationCodeOptions called");
      this.LoadingFlag = true;
      this.DesignationCodeOptionsLoading = true;
      var selectbox1_source = "DesignationCode";
      var selectbox1_destination = "DesignationCodeOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 5,
        DesignationTypeId: [532, 533, 534],
        ExceptDesignationId: [],
        AllOption: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getModuleCodeOptions(source, destination) {
      console.log("getModuleCodeOptions called");
      this.ModuleCodeOptionsLoading = true;
      this.LoadingFlag = true;
      var selectbox1_source = "ModuleCode";
      var selectbox1_destination = "ModuleCodeOptions";
      var selectbox1_url = "api/jcom-web/jcom/module/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.TableLoadingFlag = true;
        this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom-web/jcom/menu-permission/lists";
        var upload = {
          UserInterface: 1,
          DesignationCode: this.DesignationCode,
          ModuleCode: this.ModuleCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.TableLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            console.log({ records });

            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.TableLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    changePermission(MenuId, Status, DesignationId) {
      console.log({ MenuId });
      console.log({ DesignationId });

      console.log("changePermission is called");
      // var JcomMenuId = menu.JcomMenuId;
      // var Status = menu.MenuPermissionStatus;
      // var DesignationCode = this.DesignationCode;

      console.log({ Status });

      var validate1 = MenuId != "" && DesignationId != "" ? true : false;
      // var validate1 = false;
      console.log("validate1=" + validate1);

      if (validate1) {
        // this.SearchFlag = true;
        // this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom-web/jcom/menu-permission/update";
        var upload = {
          DesignationCode: DesignationId,
          JcomMenuId: MenuId,
          Status: Status,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  mounted() {
    // this.searchForm();
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    var ZoneCode = this.$session.get("LomListZoneCode");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    this.ZoneCode = ZoneCode;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#menu-permission-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>